html {
  height: 100%;
}

h1,
p {
  font-family: "Lato", sans-serif;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.map {
  height: 100%;
  position: relative;
  z-index: 10;
}

.elevation-chart {
  z-index: 9999;
  background: white;
}

.recharts-wrapper {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  border: "solid 1px #ddd";
  background: "white";
}

.minimise-chart {
  position: absolute;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.ant-select-selection {
  width: 200px;
}

.ant-card-body {
  padding: 12px;
}

.show-elevation {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  background-color: white;
  border: none;
  border-radius: 2px;
  width: 150px;
  color: rgba(0, 0, 0, 0.65);
  margin: 10px;
}

.show-elevation span {
  float: left;
}

.ant-card-body {
  padding-left: 0px;
}

.ant-menu-submenu-title {
  padding-left: 12px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info-icon{
  position: absolute;
  bottom: 20px;
  right: 50px;
  z-index: 9999;
  cursor: pointer;
  font-size: 30px;
}

.ant-popover {
  max-width: 250px;
  margin-right: 5px;
}

.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 76%;
}

.attribute-info {
  font-size: 8px;
}
