.overlayMenu {
  position: absolute;
  border-radius: 5px;
  top: 10px;
  left: 10px;
  z-index: 99;
  max-height: 80%;
  overflow-x: hidden;
}

#map-tool-heading {
  font-size: 1.4em;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  font-size: 1.3em;
}

.ant-menu-item-only-child {
  padding-left: 0;
}

.title > div.ant-menu-submenu-title {
  width: 300px;
  z-index: 150;
  margin-top: 0px;
  background-color: #f1efef;
  border-radius: 5px;
}

.sub-heading {
  font-size: larger;
}

.filter-trail-users div {
  padding-top: 0px;
}

.sub-icon {
  padding-bottom: 1px;
}

.ant-menu-submenu-title {
  margin: 0px;
}

.distance-divider {
  margin: 10px 0px 0px 0px;
  padding: 0px;
  width: 80%;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: auto;
}

.ant-select-dropdown-menu-item .ant-select-dropdown-menu-item-active {
  width: 200px;
}

.map-blurb-img {
  border: 1px solid dimgrey;
  border-radius: 5px;
  max-width: 210px;
}

.ant-menu-sub.ant-menu-inline {
  border-radius: 4px;
}

.ant-menu-submenu-title {
  padding-left: 24px !important;
}

.info-card {
  width: 250px;
  padding-top: 0px;
}

.info-card .ant-card-body {
  padding-top: 0px;
}

.map-info-divider {
  margin: 10px 0px 0px 0px;
  width: 90%;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: initial;
}

p {
  margin: 0;
}

.ant-menu-item {
  white-space: initial;
}

.ant-menu-inline .ant-menu-item {
  line-height: initial;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  line-height: initial;
}

.ant-menu-inline .ant-menu-item {
  line-height: initial;
  margin-top: 0;
}

.distance-calculator .distance-select {
  margin-bottom: 7px;
}

.distance-calculator .distance-select {
  margin-bottom: 10px;
}

.trail-users .distance-select {
  margin-bottom: 10px;
}
