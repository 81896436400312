.landing-page {
  height: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../media/background-landingpage.jpg") no-repeat center center fixed;
  background-size: cover;
}

.map-switcher-block {
  max-width: 600px;
  padding-top: 10%;
}

.map-switcher-block > h1 {
  font-family: "Shadows Into Light", cursive;
  color: #fafafa;
  font-size: 130px;
}

.ant-cascader-menu {
  height: initial;
}

.ant-cascader-menus > div {
  width: 300px;
}

.disclaimer {
  padding-top: 50px;
}

.disclaimer h2 {
  color: white;
  font-weight: 300;
  padding-bottom: 10px;
}

.disclaimer > p {
  color: white;
  font-size: 11px;
}

.disclaimer .title {
  padding-bottom:10px;
  font-size: 20px;
}
