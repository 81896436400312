
.map > .map-select {
  z-index: 100;
  width: 220px;
  position: absolute;
  right: 0px;
  font-size: 12px;
  background: white;
  margin: 10px 10px 0px 0px;
  border-radius: 5px;
}

.map > .map-select input {
  font-size: 13px;
}

.map > div > div > .ant-cascader-menus {
  width: 220px;
}

.map > div > div > .ant-cascader-menus div {
  font-size: 11px;
}

.map-switcher-div > .map-select {
  width: 300px;
  margin-left: 25%;
}
