h1,
p {
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.leaflet-popup-content-wrapper {
  border-radius: 6px;
  max-width: 180px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.leaflet-popup-content-wrapper {
  padding: 0px;
}

.leaflet-popup-tip-container {
  width: 45px;
}

.leaflet-popup-content {
  margin: 0px;
  min-width: 180px;
}

.titleDiv {
  background-color: #48b5e9;
  padding: 5px 0px;
  display: flex;
}

.titleDiv h1 {
  color: white;
  margin: 0;
  font-weight: 300;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 24px;
}

.popupText {
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
  word-wrap: break-word;
  padding-bottom: 5px;
}

.leaflet-container a.leaflet-popup-close-button {
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 2px;
  font: 20px/14px Tahoma, Verdana, sans-serif;
  color: #040404a1;
}

.titleSpan {
  display: flex;
  margin-left: auto;
  padding-right: 20px;
}

.flag {
  margin-left: 9px;
  height: 1.4em;
  font-size: 17px;
  color: #00000075;
}

.leaflet-marker-draggable {
  cursor: grab;
}

.clear-path {
  margin-left: 4px;
  padding-right: 11px;
}

.leaflet-popup-close-button {
  margin-right: 3px;
}

.marker-modal {
  z-index: 9999;
}

@media only screen and (max-width: 600px) {
  .map .map-select {
    display: none;
  }

  .info-icon {
    display: none;
  }

  .leaflet-control-zoom {
    display: none;
  }

  .elevation-chart {
    display: none;
  }
}
