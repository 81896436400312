.x-axis {
  font-size: 12px;
  color: #ccc;
  opacity: 0.5;
}

.y-axis {
  font-size: 12px;
  color: #ccc;
}

.elevation-chart {
  position: fixed !important;
  bottom: 0 !important;
  margin: 10px;
  transform: none !important;
  top: initial !important;
  border-radius: 5px;
  cursor: grab;
}

@media only screen and (max-width: 600px) {
  .elevation-chart {
    display: none !important;
  }
}
