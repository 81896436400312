.marker-type-dropdown {
  width: 200px !important;
}

.ant-modal-content .ant-form-item {
  padding-bottom: 0px;
  margin-bottom: 15px;
}

.helper-icon {
  padding-left: 5px;
}
