.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.mapboxgl-marker:hover {
  cursor: pointer;
}
